import {
  getAdaptiveTranslatedTimeFromSeconds,
  getMinutesFromSeconds,
} from "@/utils/duration";
import { clamp } from "@/utils/math";
import { getDropdownEntries, groupDropdownEntries } from "@/utils/dropdown";
import { tr } from "@/utils/translation";
import { CompensationFeedbackResult } from "@/models/CompensationFeedbackResult.model";
import ActivityDescription from "@/models/ActivityDescription.model";
import DateTimeOffset from "@/models/DateTimeOffset.model";
import { DosageKind } from "@/services/enums";
import BaseActivityResult from "@/models/activity-result/BaseActivityResult.model";

export const assistVariants = [
  "none",
  "sitting",
  "sitting-stool",
  "sitting-plinth",
  "sitting-chair",
  "standingFrame",
  "parallelBars",
  "walkingStick",
  "assistanceOfTherapy",
  "antiGravityArmSupport",
  "therapyRing",
  "many",
  "selfAssisted",
];

export const challengeVariants = [
  "none",
  "standing",
  "wobbleCushionStanding",
  "wobbleCushionSitting",
  "gymBall",
  "therapyStep",
  "therapyBand",
  "therapyBand-yellow",
  "therapyBand-red",
  "therapyBand-green",
  "therapyBand-blue",
  "therapyBand-black",
  "weights",
  "weight-free",
  "weight-wristAnkle",
  "weight-bar",
  "weight-vest",
  "weight-other",
  "weightSizes-one",
  "weightSizes-two",
  "weightSizes-three",
  "many",
];

export default class ActivityResult extends BaseActivityResult {
  constructor(activityResult) {
    super(activityResult);
    this.cloudId = activityResult.cloudId;
    this.activityId = activityResult.appId;
    this.ended = activityResult.ended;
    this.endOffset = new DateTimeOffset(activityResult.endOffset);
    this.activeTrainingTimeSeconds =
      activityResult.activeTrainingTime != null
        ? activityResult.activeTrainingTime
        : activityResult.duration;
    this.activeTrainingTime = getMinutesFromSeconds(
      this.activeTrainingTimeSeconds
    );
    this.duration = getMinutesFromSeconds(activityResult.duration);
    this.durationSeconds = activityResult.duration;
    this.activeSide = activityResult.activeSide;
    this.completed = activityResult.completed;
    this.skipped = activityResult.skipped;

    this.compensationFeedbackResults =
      activityResult.compensationFeedbackResults != null
        ? activityResult.compensationFeedbackResults.map(
            (result) => new CompensationFeedbackResult(result)
          )
        : [];

    let activityDescription = ActivityDescription.query().find(this.activityId);
    // Boolean to display or not the score according to the dosage kind
    this.showScore = activityDescription?.activityOptions?.hasScore;
    // Adds the compensation movement data for session report use.
    this.compensationMovements = activityDescription
      ? activityDescription.compensationMovements
      : [];
    this.activityName = activityDescription ? activityDescription.Name : "";
    this.movementName = activityDescription
      ? activityDescription.MovementName
      : "";
    this.dosageKind =
      activityDescription?.activityOptions?.dosage?.kind || DosageKind.DURATION;
    this.extraData = activityResult?.extraData || null;
    this.isToapRun = activityResult.appId === "ToapRun_V2";
  }

  translatedActiveSide() {
    if (!this.activeSide) {
      return "--";
    }
    return tr(`Common.Activity.Sides.${this.activeSide.toLowerCase()}`);
  }

  static getAssistsVariantList() {
    const dropdownOptions = getDropdownEntries(
      assistVariants,
      "Common.Activity.Assists"
    );
    return groupDropdownEntries(dropdownOptions);
  }

  static getChallengesVariantList() {
    const dropdownOptions = getDropdownEntries(
      challengeVariants,
      "Common.Activity.Challenges"
    );
    return groupDropdownEntries(dropdownOptions);
  }

  /**
   * Returns the formatted active training time.
   * @return {string}
   */
  getActiveTrainingTime() {
    return getAdaptiveTranslatedTimeFromSeconds(this.activeTrainingTimeSeconds);
  }

  /**
   * Returns the formatted duration.
   * @return {string}
   */
  getDuration() {
    // For logic dosage kind activities (Mind4 and Stardust), the duration is set to the active training time
    if (this.dosageKind === DosageKind.LOGIC) {
      return getAdaptiveTranslatedTimeFromSeconds(
        this.activeTrainingTimeSeconds
      );
    }
    return getAdaptiveTranslatedTimeFromSeconds(this.durationSeconds);
  }

  /**
   * Returns the calculated compensation percentage
   * (compensationduration / activeTrainingTimeSeconds * 100)
   */
  compensationPercentage() {
    let compensationPercent =
      this.activeTrainingTimeSeconds > 0.0
        ? (this.compensationDuration / this.activeTrainingTimeSeconds) * 100.0
        : 0.0;
    let percentageDisplayedResult = clamp(
      Math.round(compensationPercent),
      0.0,
      100.0
    );

    if (compensationPercent > 0.0 && compensationPercent < 1.0) {
      percentageDisplayedResult = "<1";
    }

    return tr("Common.Units.percentageDisplay", {
      percentage: percentageDisplayedResult,
      // Fixes the "<1" display to not be '&lt;1'
      interpolation: { escapeValue: false },
    });
  }

  /**
   * Indicates whether the activity results has some valid compensation details.
   * It also returns false when the compensation feedback result is 'Legacy'
   * @return {boolean}
   */
  hasCompensationDetails() {
    if (this.compensationFeedbackResults == null) {
      return false;
    }

    // Do not consider legacy feedback results
    if (
      this.compensationFeedbackResults.some((result) => result.id === "Legacy")
    ) {
      return false;
    }

    return this.compensationFeedbackResults.some((result) =>
      result.hasDetails()
    );
  }

  /**
   * Returns the race distance (for ToapRun).
   */
  getRaceDistance() {
    return this.extraData?.data?.Distance || 0;
  }

  /**
   * Returns the gestures data results (for ToapRun).
   */
  getGesturesData() {
    return this.extraData?.data?.GesturesData || null;
  }

  /**
   * Returns the successful gestures (for ToapRun).
   */
  getSuccessfulGestures() {
    const gesturesNb = this.extraData?.data?.Gestures || 0;
    const gesturesTotalNb = this.extraData?.data?.GesturesTotal || 0;
    return gesturesNb + " / " + gesturesTotalNb;
  }

  /**
   * Returns the successful gestures percentage (for ToapRun).
   */
  getSuccessfulGesturesPercent() {
    const gesturesNb = this.extraData?.data?.Gestures || 0;
    const gesturesTotalNb = this.extraData?.data?.GesturesTotal || 0;
    if (gesturesTotalNb > 0) {
      return Math.round((gesturesNb / gesturesTotalNb) * 100);
    }

    return 0;
  }

  /**
   * Returns parameters gesture states (for ToapRun).
   */
  getParamGestureStates() {
    return this.extraData?.data?.Parameters?.GesturesStates || null;
  }

  /**
   * Returns the duration parameters in minutes (for ToapRun).
   */
  getParamDurationMinutes() {
    return this.extraData?.data?.Parameters?.DurationMinutes || 0;
  }

  /**
   * Returns the race number in list (for ToapRun).
   */
  getRaceNumberInList() {
    return this.extraData?.data?.RaceNumberInList || null;
  }

  /**
   * Returns the difficulty level (for ToapRun).
   */
  getDifficultyLevel() {
    const difficulties = { 0: "easy", 1: "easy", 2: "medium", 3: "hard" };
    const difficulyIndex =
      this.extraData?.data?.Parameters?.DifficultyLevel || null;
    const subDifficulyLast =
      "+" + this.extraData?.data?.SubDifficultyLast || null;
    const difficuly = difficulties[difficulyIndex] || "";
    if (difficulyIndex != null && subDifficulyLast != null) {
      return (
        tr(
          `TherapistPatientManagementPage.ReviewPage.ActivityReport.DifficultyLevel.${difficuly}`
        ) +
        " " +
        subDifficulyLast
      );
    }

    return null;
  }
}
